import angular from "angular";

import {angularizeDirective} from "../../shared/react-in-angular.js";
import BuildNotifier from "./BuildNotifier.jsx";

export default angular.module("app.components.buildNotifier", []).name;

// matches <app-build-notifier>
angularizeDirective(BuildNotifier, "appBuildNotifier", angular.module("app.components.buildNotifier"), {
  intervalSeconds: "<",
});
