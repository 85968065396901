/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Error from "@mui/icons-material/Error";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Typography from "@mui/material/Typography";

function LogoNotFoundMessage({facilityName = "your facility"}) {
  return (
    <>
      <Typography sx={{mb: 2, display: "flex", alignItems: "center"}}>
        <Error color="error" />
        &nbsp;&nbsp;This report was generated with a facility logo that could not be found.
      </Typography>
      <Typography sx={{mb: 1}}>
        Contact your administrator to add a valid logo for {facilityName} before generating any new reports.
      </Typography>
      <Typography>Would you like to continue viewing the report using the BitRhythm default logo?</Typography>
    </>
  );
}

LogoNotFoundMessage.propTypes = {
  facilityName: PropTypes.string,
};

export default LogoNotFoundMessage;
