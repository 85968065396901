/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import AddProviders from "../../shared/react/AddProviders.jsx";
import ReportsPage from "./ReportsPage.jsx";

function ReportsPageWrapper({
  // Props
  search = "",
}) {
  //---------------------------------------------------------------------------
  // NOTE: The only reason that this file is separate from ReportsPage.jsx is because of Angular.
  //  Once we switch completely from Angular to React, AddProviders can be moved to an App.jsx so
  //  that it encapsulates the entire application. Then this file can be deleted.
  //---------------------------------------------------------------------------
  return (
    <AddProviders>
      <ReportsPage searchText={search} />
    </AddProviders>
  );
}

ReportsPageWrapper.propTypes = {
  search: PropTypes.string,
};

export default ReportsPageWrapper;
