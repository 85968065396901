import angular from "angular";

import {angularizeDirective} from "../../shared/react-in-angular.js";
import PublishedReportRejectedNotification from "./PublishedReportRejectedNotification.jsx";

export default angular.module("app.components.publishedReportRejectedNotification", []).name;

// matches <br-published-report-rejected-notification>
angularizeDirective(
  PublishedReportRejectedNotification,
  "brPublishedReportRejectedNotification",
  angular.module("app.components.publishedReportRejectedNotification"),
  {details: "<"}
);
