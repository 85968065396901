/* eslint-env browser */

import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Warning from "@mui/icons-material/Warning";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import AddProviders from "../../shared/react/AddProviders.jsx";
import Alert from "../../shared/react/Alert.jsx";
import TextWithNewlines from "../../shared/react/TextWithNewlines.jsx";

function PublishedReportRejectedNotification({
  // Props
  details,
}) {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [fileUrl, setFileUrl] = React.useState(null);

  const getReportPdf = React.useCallback(async () => {
    setLoading(true);
    try {
      const {data: pdf} = await axios({
        method: "get",
        url: details.url,
        responseType: "arraybuffer",
      });

      const file = new File([pdf], "file.pdf", {type: "application/pdf"});
      const objectUrl = URL.createObjectURL(file);
      setFileUrl(objectUrl);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }, [details.url]);

  React.useEffect(() => {
    if (details.reportType === "Uploaded" && !fileUrl) {
      getReportPdf();
    }
  }, [details.reportType, fileUrl, getReportPdf]);

  return (
    <AddProviders>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />

      <Box sx={{m: 2, p: 2, display: "inline-flex", alignItems: "flex-start"}}>
        <Warning color="warning" fontSize="large" />

        <Stack
          direction="column"
          spacing={2}
          sx={{alignItems: "flex-start", justifyContent: "flex-start", ml: 2}}
        >
          <Typography variant="subtitle1" data-cy="rejected-by">
            {details.rejectedBy?.fullName} rejected {details.reportTitle || "Report"}
          </Typography>

          <Box sx={{display: "inline-flex", alignItems: "flex-start"}}>
            <Typography variant="overline" sx={{lineHeight: 1.5}}>
              <b>Reason for rejection</b>:&nbsp;
            </Typography>
            <TextWithNewlines
              text={details.reasonForRejection}
              variant="body2"
              data-cy="reason-for-rejection"
            />
          </Box>

          <LoadingButton
            href={fileUrl || details.url}
            target="_blank"
            variant="outlined"
            color="secondary"
            loading={loading}
            data-cy="view-report-button"
          >
            View Report
          </LoadingButton>
        </Stack>
      </Box>
    </AddProviders>
  );
}

PublishedReportRejectedNotification.propTypes = {
  details: PropTypes.object.isRequired,
};

export default PublishedReportRejectedNotification;
