import angular from "angular";

import {angularizeDirective} from "../../shared/react-in-angular.js";
import ReportsPageWrapper from "./ReportsPageWrapper.jsx";

export default angular.module("app.components.reportsPage", []).name;

// matches <inbox-reports-page>
angularizeDirective(ReportsPageWrapper, "inboxReportsPage", angular.module("app.components.reportsPage"), {
  search: "<",
});
