/* @ngInject */
export default function start(
  $http,
  $rootScope,
  Authentication,
  WorkflowsService,
  $state,
  $window,
  $transitions,
  $document
) {
  $rootScope.$state = $state;
  $transitions.onStart({}, function authenticate(transitions) {
    const toState = transitions.$to();
    if (Authentication.isAuthenticated()) {
      toState.firstLoad = true; // Used in the Inbox Item Service for Loading Icon

      // if user does not have correct role for the page
      if (toState.data?.roles && !Authentication.isInAnyRole(toState.data.roles)) {
        // Don't allow state transition, user not authorized
        console.error("You are not authorized to access the requested page");
        transitions.abort();

        // Redirect user to their usual landing page
        const initialState = Authentication.getInitialState();
        $state.go(initialState);
      }
    } else if (toState.parent.name === "App" && !$window.location.href.includes("login")) {
      transitions.abort();
      Authentication.sendToLogin(null, null, toState.name);
    }

    if (toState.name === "activateAccount" || toState.name === "requestReset") {
      Authentication.removeJwt();
    }
  });

  $transitions.onSuccess({}, function scrollToTop(transitions) {
    $document[0].body.scrollTop = 0;
    $document[0].documentElement.scrollTop = 0;

    const pageName = transitions.$to().name;
    const capitalizedName = pageName
      .split(" ")
      .map((word) => {
        return word[0].toUpperCase() + word.substr(1);
      })
      .join(" ");

    $document[0].title = `${capitalizedName} - BitRhythm Inbox`;
  });
}
