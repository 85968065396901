import angular from "angular";

import {angularizeDirective} from "../../shared/react-in-angular.js";
import AvatarMenuWrapper from "./AvatarMenuWrapper.jsx";

export default angular.module("app.components.avatarMenu", []).name;

// matches <br-avatar-menu>
angularizeDirective(AvatarMenuWrapper, "brAvatarMenu", angular.module("app.components.avatarMenu"), {
  logout: "<",
});
