/* eslint-env browser */
import React from "react";
import {useForm} from "react-hook-form";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import FindInPage from "@mui/icons-material/FindInPage";
import LibraryBooks from "@mui/icons-material/LibraryBooks";
import Person from "@mui/icons-material/Person";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import Alert from "../../shared/react/Alert.jsx";
import FormStringInput from "../../shared/react/FormStringInput.jsx";
import IconWithText from "../../shared/react/IconWithText.jsx";

function RejectConfirmation({open, handleClose, onSubmit, report, submitting = false}) {
  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const {handleSubmit, control} = useForm();

  return (
    <Dialog open={open} data-cy="reject-confirmation-dialog">
      <DialogTitle>Reject {report.reportType} Report?</DialogTitle>
      <DialogContent>
        <Alert
          message="Are you sure you want to reject this report?"
          level="warning"
          otherProps={{sx: {mb: 1}}}
        />
        <Grid container sx={{pl: 2}}>
          <Grid size={12}>
            <IconWithText
              icon={<FindInPage color="secondary" fontSize="small" />}
              text={<Typography sx={{fontSize: 14}}>Study ID: {report.studyId}</Typography>}
            />
          </Grid>
          <Grid size={12} sx={{display: "inline-flex", alignItems: "center"}}>
            <IconWithText
              icon={<Person color="secondary" fontSize="small" />}
              text={
                <Typography sx={{fontSize: 14}}>
                  {report.studyDetails?.patientName || report.tzSerial}
                </Typography>
              }
            />
          </Grid>
          <Grid size={12} sx={{display: "inline-flex", alignItems: "center"}}>
            <IconWithText
              icon={<LibraryBooks color="secondary" fontSize="small" />}
              text={<Typography sx={{fontSize: 14}}>Report Number: {report.reportNumber}</Typography>}
            />
          </Grid>
        </Grid>

        <Box sx={{mt: 3}}>
          <FormStringInput
            control={control}
            name="reasonForRejection"
            label="Reason"
            defaultValue=""
            disabled={submitting}
            data-cy="reason-for-rejection-input"
            otherProps={{variant: "outlined", multiline: true, rows: 4}}
            rules={{required: "Reasoning is required"}}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" data-cy="cancel-confirmation-button">
          Cancel
        </Button>
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          color="secondary"
          variant="contained"
          data-cy="confirm-button"
          loading={submitting}
          disabled={submitting}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

RejectConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  report: PropTypes.object.isRequired,
  submitting: PropTypes.bool,
};

export default RejectConfirmation;
