import angular from "angular";

import {angularizeDirective} from "../../shared/react-in-angular.js";
import DateAndTime from "./DateAndTime.jsx";

export default angular.module("app.components.dateAndTime", []).name;

// matches <br-date-time>
angularizeDirective(DateAndTime, "brDateTime", angular.module("app.components.dateAndTime"), {
  datetime: "<",
  zone: "<",
  seconds: "<",
  format: "<",
  fallback: "<",
});
