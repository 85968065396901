/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useFilter, useInterval, useLocalStorage} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import {useReports, useReportsDispatch} from "../../contexts/ReportsContext.jsx";
import Alert from "../../shared/react/Alert.jsx";
import TableLoading from "../../shared/react/TableLoading.jsx";
import useJwt from "../hooks/useJwt.jsx";
import useStudyTypeNames from "../hooks/useStudyTypeNames.jsx";
import ReportsTable from "./ReportsTable.jsx";

// Unless we can get socket.io or long polling working, fetching the data
// every 15 seconds should keep things from getting "stale"
const DATA_REFRESH_INTERVAL_MS = 15000;

//---------------------------------------------------------------------------
// Search options
//---------------------------------------------------------------------------
const constantSearchFields = {
  study: "studyId",
  facility: "facilityName",
  number: "reportNumber",
  findings: "comment",
  comments: "physicianComment",
  type: (object) =>
    object.reportType === "Single Episode"
      ? `${object.reportType} - ${object.eventClassification}`
      : object.reportType,
  is: {
    mdn: (object) => object.meetsMdnCriteria,
  },
  timestamp: "timestamp",
  patient: [(object) => object.studyDetails?.patientName, (object) => object.studyDetails?.patientId],
  gender: (object) => object.studyDetails?.patientGender,
  "physician-name": (object) => object.studyDetails?.physicianName,
  "physician-phone": (object) => object.studyDetails?.physicianPhone,
  "physician-facility": (object) => object.studyDetails?.physicianFacility,
  "physician-email": (object) => object.studyDetails?.physicianEmail,
  "physician-address": (object) => object.studyDetails?.physicianAddress,
  physician: [
    (object) => object.studyDetails?.physicianName,
    (object) => object.studyDetails?.physicianPhone,
    (object) => object.studyDetails?.physicianFacility,
    (object) => object.studyDetails?.physicianEmail,
    (object) => object.studyDetails?.physicianAddress,
  ],
};

function ReportsPage({
  // Props
  searchText = "",
}) {
  const displayableStudyTypes = useStudyTypeNames();
  const {username, isInAnyRole} = useJwt();

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);

  //---------------------------------------------------------------------------
  // Status filter support
  //---------------------------------------------------------------------------
  const defaultFilter = React.useMemo(
    () => ({
      ...(!isInAnyRole(["physician", "clinicalStaff"]) && {generated: true}),
      submitted: true,
      published: true,
      signed: !isInAnyRole(["physician"]),
      printed: !isInAnyRole(["physician"]),
      ...(!isInAnyRole(["physician", "clinicalStaff"]) && {rejectedByTech: false}),
      rejectedByPhysician: !isInAnyRole(["physician", "clinicalStaff"]),
    }),
    [isInAnyRole]
  );
  const [statusFilter, setStatusFilter] = useLocalStorage(`${username}.reports.filter`, defaultFilter);

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(true);
  const reports = useReports();
  const dispatch = useReportsDispatch();
  const axiosAbortController = React.useRef();

  const getReports = React.useCallback(async () => {
    // filter the reports by the current status filter from local storage
    const statusArray = Object.keys(statusFilter).filter((status) => statusFilter[status]);

    // WARNING: axios strips off empty strings
    // if there are no filters toggled on let the back end know
    if (statusArray.length === 0) {
      statusArray.push("none");
    }

    // abort the previous request to avoid race conditions
    if (axiosAbortController.current) {
      axiosAbortController.current.abort();
    }
    axiosAbortController.current = new AbortController();

    try {
      const {data: reportsResponse} = await axios({
        method: "get",
        url: "/generatedReports",
        params: {
          status: statusArray,
        },
        signal: axiosAbortController.current.signal,
      });

      dispatch({type: "init", elements: reportsResponse});

      setLoading(false);
    } catch (err) {
      console.error(err);
      if (err.message === "canceled") {
        // If the axios request was cancelled by a new axios request, don't display an error, and don't set loading to false
        setError(null);
      } else if (err.response?.status === 500 && reports.length > 0) {
        // Don't display error in this case
        setError(null);
        setLoading(false);
      } else {
        // Display all other errors
        setError(err?.message);
        setLoading(false);
      }
    }
  }, [dispatch, statusFilter, reports.length]);

  useInterval(getReports, DATA_REFRESH_INTERVAL_MS, loading);

  //---------------------------------------------------------------------------
  // Search support
  //---------------------------------------------------------------------------
  const searchFields = React.useMemo(() => {
    return {
      ...constantSearchFields,
      "study-type": [(object) => object.studyType, (object) => displayableStudyTypes[object.studyType]],
    };
  }, [displayableStudyTypes]);

  const filteredReports = useFilter(reports, searchText, searchFields);

  const mdnReports = React.useMemo(
    () => filteredReports.filter((report) => report.meetsMdnCriteria),
    [filteredReports]
  );
  const nonMdnReports = React.useMemo(
    () => filteredReports.filter((report) => !report.meetsMdnCriteria),
    [filteredReports]
  );

  return (
    <>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
      <Alert message={success} setMessage={setSuccess} level="info" variant="snackbar" />

      {loading && <TableLoading />}
      {!loading && (
        <>
          {/* MDN CRITERIA REPORTS */}
          <ReportsTable
            search={searchText}
            pageSize={10}
            reports={mdnReports}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            displayNoResults={false}
            setFilterLoading={setLoading}
            setSuccess={setSuccess}
          />

          {/* NON MDN CRITERIA REPORTS */}
          <ReportsTable
            search={searchText}
            pageSize={20}
            reports={nonMdnReports}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            setFilterLoading={setLoading}
            setSuccess={setSuccess}
          />
        </>
      )}
    </>
  );
}

ReportsPage.propTypes = {
  searchText: PropTypes.string,
};

export default ReportsPage;
