import angular from "angular";

import {angularizeDirective} from "../../shared/react-in-angular.js";
import AutoLogoutWrapper from "./AutoLogoutWrapper.jsx";

export default angular.module("app.components.autoLogout", []).name;

// matches <br-auto-logout>
angularizeDirective(AutoLogoutWrapper, "brAutoLogout", angular.module("app.components.autoLogout"), {
  throttle: "<",
  warningSeconds: "<",
});
