/* eslint-env browser */
import axios from "axios";

const httpClient = axios.create({
  ...(window.globalConfig && {baseURL: window.globalConfig.apiUrl}),
  paramsSerializer: {
    // Escape the following characters just to be safe
    encode: (val) =>
      val
        .toString()
        .replace(/%/gi, "%25")
        .replace(/\+/gi, "%2B")
        .replace(/\[/gi, "%5B")
        .replace(/\]/gi, "%5D")
        .replace(/\$/gi, "%24"),
  },
});

// adds access tokens in all api requests
httpClient.interceptors.request.use(async (config) => {
  const storageValue = localStorage.getItem("jwt");
  if (!storageValue) {
    // If the JWT has been deleted, don't try to attach the header
    return config;
  }
  let token;
  try {
    token = JSON.parse(storageValue).value;
  } catch (err) {
    console.error(err);
    token = storageValue;
  }
  return {...config, ...{headers: {...config.headers, Authorization: `Bearer ${token}`}}};
});

export default httpClient;
