import angular from "angular";

import {angularizeDirective} from "../../shared/react-in-angular.js";
import EditReportWarning from "./EditReportWarning.jsx";

export default angular.module("app.components.editReportWarning", []).name;

// matches <br-edit-report-warning>
angularizeDirective(
  EditReportWarning,
  "brEditReportWarning",
  angular.module("app.components.editReportWarning"),
  {}
);
