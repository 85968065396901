/* eslint-env browser */

//------------------------------------------------------------------------------
//             __             __   ___  __
//     | |\ | /  ` |    |  | |  \ |__  /__`
//     | | \| \__, |___ \__/ |__/ |___ .__/
//
//------------------------------------------------------------------------------

import React, {useEffect} from "react";
import {createPortal} from "react-dom";
import {DateTime, IANAZone, Interval} from "luxon";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import ZoomOut from "@mui/icons-material/ZoomOut";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import AddProviders from "../../shared/react/AddProviders.jsx";
import Alert from "../../shared/react/Alert.jsx";
import IconButtonWithTooltip from "../../shared/react/IconButtonWithTooltip.jsx";
import PvcBurdenGraph from "./PvcBurdenGraph.jsx";

function PvcBurden({
  // Props
  pvc,
  toggles,
  study,
  type,
}) {
  //---------------------------------------------------------------------------
  // The following variables are the prop names that _should be_ if we weren't
  // using pug templates to connect this to angularjs.
  //---------------------------------------------------------------------------
  const chartToggles = toggles;
  const preloadedPvcBurden = pvc;
  const parentType = type;

  //---------------------------------------------------------------------------
  // Chart Toggle state management
  //
  // If the chartToggles are not applicable (e.g. on Active Study cards), set
  // checked to true
  //
  // Note: Move all toggle logic outside of this graph component once
  // report items are converted to React
  //---------------------------------------------------------------------------
  const [checked, setChecked] = React.useState(
    chartToggles?.pvcBurden === undefined ? true : chartToggles?.pvcBurden
  );
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  // If switch is toggled, update the chartToggles object that is used in the
  // reportItem Angular controller
  useEffect(() => {
    if (chartToggles) {
      chartToggles.pvcBurden = checked;
    }
  }, [chartToggles, checked]);

  //---------------------------------------------------------------------------
  // Error handling state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Zoom Out button management
  //---------------------------------------------------------------------------
  const [zoomedIn, setZoomedIn] = React.useState(false);
  const [clickedZoomOut, setClickedZoomOut] = React.useState(false);
  const handleZoomOut = React.useCallback(() => {
    setClickedZoomOut(true); // Communicate to all child graphs that Zoom Out has been clicked
  }, []);

  //---------------------------------------------------------------------------
  // Determine the start and end time for this graph data
  //---------------------------------------------------------------------------
  const thirtyDaysAfterStart = React.useMemo(
    () => DateTime.fromISO(study.studyStartDate).plus({days: 30}),
    [study.studyStartDate]
  );

  const startTime = React.useMemo(() => {
    // For Daily Trends, set start date to exactly 24hours before the item timestamp
    if (parentType === "Daily Trend") {
      let startDate = DateTime.fromISO(study.timestamp);

      if (study.timeZone && IANAZone.isValidZone(study.timeZone)) {
        startDate = startDate.setZone(study.timeZone);
      }

      return startDate.minus({days: 1});
    }

    // For Summary items or Study cards, set start date to the beginning of the studyStartDate's week
    let startDate = DateTime.fromISO(study.studyStartDate);

    // We need to use the study's time zone when using `useLocalWeeks`
    if (study.timeZone && IANAZone.isValidZone(study.timeZone)) {
      startDate = startDate.setZone(study.timeZone);
    }
    return startDate.startOf("week", {useLocaleWeeks: true});
  }, [parentType, study.studyStartDate, study.timeZone, study.timestamp]);

  const endTime = React.useMemo(() => {
    // For Daily Trends, set end date to the item timestamp
    if (parentType === "Daily Trend") {
      let endDate = DateTime.fromISO(study.timestamp);

      if (study.timeZone && IANAZone.isValidZone(study.timeZone)) {
        endDate = endDate.setZone(study.timeZone);
      }
      return endDate;
    }

    // For Summary items or Study cards, set end date to the end of the studyEndDate's week
    let endDate = study.studyEndDate
      ? DateTime.fromISO(study.studyEndDate)
      : DateTime.min(DateTime.now(), thirtyDaysAfterStart);

    // We need to use the study's time zone when using `useLocalWeeks`
    if (study.timeZone && IANAZone.isValidZone(study.timeZone)) {
      endDate = endDate.setZone(study.timeZone);
    }
    return endDate.endOf("week", {useLocaleWeeks: true});
  }, [parentType, study.studyEndDate, study.timeZone, study.timestamp, thirtyDaysAfterStart]);

  const weeks = React.useMemo(
    () => Interval.fromDateTimes(startTime, endTime).splitBy({weeks: 1}),
    [endTime, startTime]
  );

  // If the PVC Burden data was already loaded, we need to manually split up the data between the weeks
  if (preloadedPvcBurden) {
    weeks.forEach((week) => {
      week.data = preloadedPvcBurden.filter(
        (pvcBurden) => week.start.toMillis() <= pvcBurden.mt && pvcBurden.mt < week.end.toMillis()
      );
    });
  }

  //---------------------------------------------------------------------------
  // Render the graph(s) and toggle
  //---------------------------------------------------------------------------
  return (
    <AddProviders>
      {!preloadedPvcBurden && (
        <>
          <Grid container sx={{alignItems: "center"}}>
            <Grid size={2} />
            <Grid size={8} sx={{textAlign: "center"}}>
              <h3 className={checked ? undefined : "disabled"}>PVC Burden</h3>
            </Grid>
            <Grid size={2}>
              <Box sx={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                {zoomedIn && (
                  <IconButtonWithTooltip
                    title="Reset Zoom"
                    onClick={handleZoomOut}
                    color="secondary"
                    otherProps={{size: "small", ...(!chartToggles && {sx: {mr: 6}})}}
                  >
                    <ZoomOut />
                  </IconButtonWithTooltip>
                )}
                {chartToggles && (
                  <Tooltip title={checked ? "Exclude from Report" : "Include in Report"}>
                    <Switch
                      sx={{mr: 1.5}}
                      color="secondary"
                      checked={checked}
                      onChange={handleChange}
                      id={`${study.id}-pvc-burden-chart-toggle`}
                    />
                  </Tooltip>
                )}
              </Box>
            </Grid>
          </Grid>

          {weeks.map((week) => (
            <PvcBurdenGraph
              key={week}
              study={study}
              setError={setError}
              checked={checked}
              startTime={parentType === "Daily Trend" ? startTime : week.start}
              endTime={parentType === "Daily Trend" ? endTime : week.end}
              clickedZoomOut={clickedZoomOut}
              setClickedZoomOut={setClickedZoomOut}
              setZoomedIn={setZoomedIn}
              isDailyTrend={parentType === "Daily Trend"}
            />
          ))}

          <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
        </>
      )}

      {/**
       * If this is a generated report, we need to manually insert each graph section into the DOM
       * so that overflow correctly moves onto the next page
       */}
      {preloadedPvcBurden &&
        weeks.map((week, index) =>
          document.getElementById(`pvc-burden-section-${index}`)
            ? createPortal(
                <PvcBurdenGraph
                  key={week}
                  preloadedPvcBurden={week.data}
                  study={study}
                  setError={setError}
                  checked={checked}
                  startTime={parentType === "Daily Trend" ? startTime : week.start}
                  endTime={parentType === "Daily Trend" ? endTime : week.end}
                  clickedZoomOut={clickedZoomOut}
                  setClickedZoomOut={setClickedZoomOut}
                  setZoomedIn={setZoomedIn}
                  isDailyTrend={parentType === "Daily Trend"}
                />,
                document.getElementById(`pvc-burden-section-${index}`)
              )
            : null
        )}
    </AddProviders>
  );
}

PvcBurden.propTypes = {
  pvc: PropTypes.array,
  toggles: PropTypes.object,
  study: PropTypes.object,
  type: PropTypes.string,
};

export default PvcBurden;
