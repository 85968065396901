/* eslint-env browser */
import React from "react";
import {darken, lighten} from "polished";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Chip from "@mui/material/Chip";
import {useTheme} from "@mui/material/styles";

function ReportRow({
  // Props
  status,
  "data-cy": dataCy,
}) {
  const theme = useTheme();

  const {label, color, backgroundColor} = React.useMemo(() => {
    if (status === "generated") {
      return {
        label: "Pending QC",
        color: darken(0.1, theme.palette.warning.main),
        backgroundColor: lighten(0.45, theme.palette.warning.main),
      };
    }
    if (status === "submitted") {
      return {
        label: "Pending Signature",
        color: darken(0.1, theme.palette.secondary.main),
        backgroundColor: lighten(0.35, theme.palette.secondary.main),
      };
    }
    if (status === "published") {
      return {
        label: "Published",
        color: darken(0.1, theme.palette.primary.main),
        backgroundColor: lighten(0.65, theme.palette.primary.dark),
      };
    }
    if (status === "signed") {
      return {
        label: "Signed",
        color: darken(0.1, theme.palette.success.main),
        backgroundColor: lighten(0.6, theme.palette.success.main),
      };
    }
    if (status === "printed") {
      return {
        label: "Printed",
        color: darken(0.1, theme.palette.tertiary.main),
        backgroundColor: lighten(0.6, theme.palette.tertiary.main),
      };
    }
    if (status === "rejectedByTech") {
      return {label: "Rejected by Tech", color: theme.palette.text.primary};
    }
    if (status === "rejectedByPhysician") {
      return {label: "Rejected by Clinic", color: theme.palette.text.primary};
    }

    // Fallback
    return {
      label: "Pending QC",
      color: darken(0.1, theme.palette.warning.main),
      backgroundColor: lighten(0.45, theme.palette.warning.main),
    };
  }, [status, theme]);

  return (
    <Chip
      label={label}
      size="small"
      variant="outlined"
      sx={{width: "100%", color, borderColor: color, backgroundColor}}
      data-cy={dataCy}
    />
  );
}

ReportRow.propTypes = {
  status: PropTypes.string.isRequired,
  "data-cy": PropTypes.string,
};

export default ReportRow;
