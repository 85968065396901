/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import useJwt from "../hooks/useJwt.jsx";
import Filter from "./FilterMenu.jsx";
import ReportStatusChip from "./ReportStatusChip.jsx";

function ReportStatusFilter({
  // Props
  statusFilter,
  setStatusFilter,
  setFilterLoading,
}) {
  //---------------------------------------------------------------------------
  // Allowed statuses depending on user
  //---------------------------------------------------------------------------
  const {isInAnyRole} = useJwt();
  const statuses = React.useMemo(() => {
    const allowedStatuses = [
      ...(!isInAnyRole(["physician", "clinicalStaff"]) ? ["generated"] : []),
      "submitted",
      "published",
      "signed",
      "printed",
      ...(!isInAnyRole(["physician", "clinicalStaff"]) ? ["rejectedByTech"] : []),
      "rejectedByPhysician",
    ];

    return allowedStatuses.map((status) => ({
      id: status,
      label: <ReportStatusChip status={status} />,
    }));
  }, [isInAnyRole]);

  //---------------------------------------------------------------------------
  // Submit
  //---------------------------------------------------------------------------
  const onSubmit = React.useCallback(
    async (updatedStatuses) => {
      setStatusFilter(updatedStatuses);
      setFilterLoading(true);
    },
    [setStatusFilter, setFilterLoading]
  );

  return (
    <Filter
      data-cy="status"
      title="Filter Status"
      options={statuses}
      optionsFilter={statusFilter}
      onSubmit={onSubmit}
    />
  );
}
ReportStatusFilter.propTypes = {
  statusFilter: PropTypes.object.isRequired,
  setStatusFilter: PropTypes.func,
  setFilterLoading: PropTypes.func,
};
export default ReportStatusFilter;
