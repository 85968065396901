import angular from "angular";

import {angularizeDirective} from "../../shared/react-in-angular.js";
import AuthenticationDialog from "./AuthenticationDialog.jsx";

export default angular.module("app.components.twoFactorAuth", []).name;

// matches <br-two-factor-auth>
angularizeDirective(AuthenticationDialog, "brTwoFactorAuth", angular.module("app.components.twoFactorAuth"), {
  username: "<",
  email: "<",
  display: "<",
});
