/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useSort} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import NoResults from "../../shared/react/NoResults.jsx";
import Pagination from "../../shared/react/Pagination.jsx";
import ReportRow from "./ReportRow.jsx";
import ReportsHeader from "./ReportsHeader.jsx";

//---------------------------------------------------------------------------
// Sort options
//---------------------------------------------------------------------------
const sortOptions = {
  defaultSort: {
    field: "timestamp",
    reverse: true,
  },
  fieldGetters: {
    patientName: (report) => report.studyDetails?.patientName,
  },
};

function ReportsTable({
  // Props
  pageSize = 50,
  reports,
  statusFilter,
  setStatusFilter,
  displayNoResults = true,
  setFilterLoading,
  setSuccess,
}) {
  //---------------------------------------------------------------------------
  // Sorting support
  //---------------------------------------------------------------------------
  const [sortedReports, handleSortSelection, sort] = useSort(reports, sortOptions);

  //---------------------------------------------------------------------------
  // Pagination support
  //---------------------------------------------------------------------------
  const [page, setPage] = React.useState(0);
  const pageReports = React.useMemo(
    () => sortedReports.slice(page * pageSize, (page + 1) * pageSize),
    [page, pageSize, sortedReports]
  );

  return (
    <Box data-cy="reports-table">
      {(sortedReports.length > 0 || displayNoResults) && (
        <ReportsHeader
          sort={sort}
          setSort={handleSortSelection}
          sortedStudies={sortedReports}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          setFilterLoading={setFilterLoading}
        />
      )}
      {sortedReports.length === 0 && displayNoResults && <NoResults />}
      {sortedReports.length > 0 && (
        <>
          {pageReports.map((report) => (
            <ReportRow key={report.id} report={report} setSuccess={setSuccess} />
          ))}
          <Pagination pageSize={pageSize} page={page} setPage={setPage} count={sortedReports.length} />
        </>
      )}
    </Box>
  );
}

ReportsTable.propTypes = {
  pageSize: PropTypes.number,
  reports: PropTypes.array.isRequired,
  statusFilter: PropTypes.object,
  setStatusFilter: PropTypes.func,
  displayNoResults: PropTypes.bool,
  setFilterLoading: PropTypes.func,
  setSuccess: PropTypes.func,
};

export default ReportsTable;
