/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import AddProviders from "../../shared/react/AddProviders.jsx";
import AutoLogout from "./AutoLogout.jsx";

function AutoLogoutWrapper({
  // Props
  throttle,
  warningSeconds,
}) {
  //---------------------------------------------------------------------------
  // NOTE: The only reason that this file is separate from AutoLogout.jsx is because of Angular.
  //  Once we switch completely from Angular to React, AddProviders can be moved to an App.jsx so
  //  that it encapsulates the entire application. Then this file can be deleted.
  //---------------------------------------------------------------------------
  return (
    <AddProviders>
      <AutoLogout throttle={throttle} warningSeconds={warningSeconds} />
    </AddProviders>
  );
}

AutoLogoutWrapper.propTypes = {
  throttle: PropTypes.number.isRequired,
  warningSeconds: PropTypes.number.isRequired,
};

export default AutoLogoutWrapper;
