import angular from "angular";

import {angularizeDirective} from "../../shared/react-in-angular.js";
import HighEventLoadDetectedNotification from "./HighEventLoadDetectedNotification.jsx";

export default angular.module("app.components.highEventLoadDetectedNotification", []).name;

// matches <br-high-event-load-detected-notification>
angularizeDirective(
  HighEventLoadDetectedNotification,
  "brHighEventLoadDetectedNotification",
  angular.module("app.components.highEventLoadDetectedNotification"),
  {details: "="}
);
