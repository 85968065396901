/* eslint-env browser */
import React from "react";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import AddProviders from "../../shared/react/AddProviders.jsx";
import DisabledMessage from "./DisabledMessage.jsx";

function DisabledMessageWrapper() {
  //---------------------------------------------------------------------------
  // NOTE: The only reason that this file is separate from DisabledMessage.jsx is because of Angular.
  //  Once we switch completely from Angular to React, AddProviders can be moved to an App.jsx so
  //  that it encapsulates the entire application. Then this file can be deleted.
  //---------------------------------------------------------------------------
  return (
    <AddProviders>
      <DisabledMessage />
    </AddProviders>
  );
}

export default DisabledMessageWrapper;
