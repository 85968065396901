/* eslint-env browser */
import React from "react";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import ReactAlert from "@mui/material/Alert";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import AddProviders from "../../shared/react/AddProviders.jsx";

function EditReportWarning() {
  return (
    <AddProviders>
      <ReactAlert severity="warning" variant="filled">
        Data may have changed since report generation.
      </ReactAlert>
    </AddProviders>
  );
}

EditReportWarning.propTypes = {};

export default EditReportWarning;
