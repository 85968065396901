import angular from "angular";

import {angularizeDirective} from "../../shared/react-in-angular.js";
import DisabledMessageWrapper from "./DisabledMessageWrapper.jsx";

export default angular.module("app.components.disabledMessage", []).name;

// matches <br-disabled-message>
angularizeDirective(
  DisabledMessageWrapper,
  "brDisabledMessage",
  angular.module("app.components.disabledMessage"),
  {}
);
