/* eslint-env browser */
import React from "react";
import {DateTime} from "luxon";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import AddCircle from "@mui/icons-material/AddCircle";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import {useSort} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import AddProviders from "../../shared/react/AddProviders.jsx";
import Alert from "../../shared/react/Alert.jsx";
import ColumnHeader from "../../shared/react/ColumnHeader.jsx";
import IconButtonWithTooltip from "../../shared/react/IconButtonWithTooltip.jsx";
import PatientDiaryNote from "../../shared/react/PatientDiaryNote.jsx";

function PatientDiaryTable({
  // Props
  study,
}) {
  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const [patientDiary, setPatientDiary] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [hasFetched, setHasFetched] = React.useState(false);
  const studyId = study.studyId || study.id;

  //---------------------------------------------------------------------------
  // State management - Diary Notes
  //---------------------------------------------------------------------------
  const defaultSort = {
    field: "timestamp",
    reverse: false,
  };
  const [sortedPatientDiary, handleSortSelection, sort] = useSort(patientDiary, {defaultSort});

  const getNoteTemplate = React.useCallback(
    (basePatientDiary = []) => {
      // Determine tempId: max(tempIds) + 1. Returns 1 if empty array
      const tempId = basePatientDiary.reduce((max, n) => Math.max(max, n.tempId || 0), 0) + 1;

      let timestamp;
      if (sort.field === "timestamp" && sort.reverse) {
        // Use the minimum timestamp in the list (ISO timestamps are sortable) Returns null if empty array
        const minTimestamp = basePatientDiary.reduce(
          (min, n) => (min < n.timestamp ? min : n.timestamp),
          null
        );
        timestamp = minTimestamp || study.studyStartDate || new Date().toISOString();

        // Subtract 1ms if possible
        if (study.studyStartDate && timestamp > study.studyStartDate) {
          timestamp = DateTime.fromISO(timestamp).minus(1000).toJSDate().toISOString();
        }
      } else {
        // Use the maximum timestamp in the list (ISO timestamps are sortable) Returns null if empty array
        const maxTimestamp = basePatientDiary.reduce(
          (max, n) => (max > n.timestamp ? max : n.timestamp),
          null
        );
        timestamp = maxTimestamp || study.studyStartDate || new Date().toISOString();

        // Add 1ms if possible
        if (timestamp < new Date().toISOString() && study.studyEndDate && timestamp < study.studyEndDate) {
          timestamp = DateTime.fromISO(timestamp).plus(1000).toJSDate().toISOString();
        }
      }

      return {
        studyId,
        timestamp,
        symptom: "",
        activity: "",
        comments: "",
        tempId,
      };
    },
    [sort.field, sort.reverse, studyId, study.studyStartDate, study.studyEndDate]
  );

  const getAndSetPatientDiaryNotes = React.useCallback(
    async (tempIdToDelete) => {
      try {
        const {data: fetchedNotes} = await axios({
          method: "get",
          url: `/studies/${studyId}/patientDiaryNotes`,
        });

        if (hasFetched) {
          // Updates notes, adds new notes, and removes DB deleted notes
          const newDiary = [
            ...patientDiary.filter((n) => !n.id), // Remove all notes with a database-issued ID
            ...fetchedNotes, // Append all notes received from the API call
          ];

          // Removes the note that was just saved or the pending note that was just deleted
          if (tempIdToDelete) {
            const indexToRemove = newDiary.findIndex((n) => n.tempId === tempIdToDelete);
            if (indexToRemove !== -1) {
              newDiary.splice(indexToRemove, 1);
            }
          }

          // Add a template if there are no notes in the table
          if (newDiary.length === 0) {
            newDiary.push(getNoteTemplate());
          }
          setPatientDiary(newDiary);
        } else if (fetchedNotes.length === 0) {
          setPatientDiary([getNoteTemplate()]);
        } else {
          setPatientDiary(fetchedNotes);
        }
        setHasFetched(true);
      } catch (err) {
        setError(err.message);
      }
    },
    [hasFetched, patientDiary, getNoteTemplate, studyId]
  );

  React.useEffect(() => {
    if (!hasFetched) {
      setLoading(true);
      getAndSetPatientDiaryNotes().then(() => {
        setLoading(false);
      });
    }
  }, [hasFetched, setLoading, getAndSetPatientDiaryNotes]);

  //---------------------------------------------------------------------------
  // Callbacks
  //---------------------------------------------------------------------------
  const onSave = React.useCallback(
    async (newDiaryNote, tempIdToDelete) => {
      await axios({
        method: "post",
        url: `/studies/${studyId}/patientDiaryNotes`,
        data: newDiaryNote,
      });

      await getAndSetPatientDiaryNotes(tempIdToDelete);
    },
    [studyId, getAndSetPatientDiaryNotes]
  );

  const onUpdate = React.useCallback(
    async (idToUpdate, changes) => {
      await axios({
        method: "patch",
        url: `/studies/${studyId}/patientDiaryNotes/${idToUpdate}`,
        data: changes,
      });

      await getAndSetPatientDiaryNotes();
    },
    [studyId, getAndSetPatientDiaryNotes]
  );

  const onDelete = React.useCallback(
    async (idToDelete, tempIdToDelete) => {
      if (idToDelete) {
        await axios({
          method: "delete",
          url: `/studies/${studyId}/patientDiaryNotes/${idToDelete}`,
        });
      }

      await getAndSetPatientDiaryNotes(tempIdToDelete);
    },
    [studyId, getAndSetPatientDiaryNotes]
  );

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <AddProviders>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
      <Typography variant="subtitle1" sx={{pb: 1}}>
        Patient Diary Notes
      </Typography>

      <Grid container columnSpacing={2} columns={20} className="headerRow">
        <Grid size={{xs: 12, md: 5}}>
          <ColumnHeader
            id="timestamp"
            display="Date and Time"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size={4} sx={{display: {xs: "none", md: "block"}}}>
          <ColumnHeader
            id="symptom"
            display="Symptom"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size={4} sx={{display: {xs: "none", md: "block"}}}>
          <ColumnHeader
            id="activity"
            display="Activity"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size="grow" sx={{display: {xs: "none", md: "block"}}}>
          <ColumnHeader
            id="comments"
            display="Additional Comments"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
      </Grid>

      <Divider sx={{mb: 1}} />

      {loading && (
        <Box>
          <CircularProgress color="secondary" size={40} />
        </Box>
      )}

      {!loading && (
        <Stack
          direction="column"
          divider={<Divider flexItem />}
          spacing={1}
          data-cy="patient-diary-notes-table"
        >
          {sortedPatientDiary.map((diaryNote, index) => (
            <PatientDiaryNote
              key={diaryNote.id || diaryNote.tempId}
              study={study}
              index={index}
              note={diaryNote}
              onDelete={onDelete}
              onUpdate={onUpdate}
              onSave={onSave}
              setError={setError}
            />
          ))}
        </Stack>
      )}

      <IconButtonWithTooltip
        title="Add Diary Note"
        color="secondary"
        data-cy="add-diary-note-button"
        onClick={() => {
          setPatientDiary((prev) => [...prev, getNoteTemplate(prev)]);
        }}
        otherProps={{autoFocus: true}}
      >
        <AddCircle />
      </IconButtonWithTooltip>
    </AddProviders>
  );
}

PatientDiaryTable.propTypes = {
  study: PropTypes.object.isRequired,
};

export default PatientDiaryTable;
