/* eslint-env browser */

import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import AddProviders from "../../shared/react/AddProviders.jsx";
import Alert from "../../shared/react/Alert.jsx";

function HighEventLoadDetectedNotification({
  // Props
  details,
}) {
  const message = React.useMemo(
    () => (
      <Typography variant="inherit" data-cy="high-event-load-detected-message">
        This study has generated events at a rate that exceeds {details.eventsPerHourThreshold} events per
        hour. This may indicate an issue with the study.
      </Typography>
    ),
    [details.eventsPerHourThreshold]
  );

  return (
    <AddProviders>
      <Alert message={message} level="warning" otherProps={{my: 2}} />
    </AddProviders>
  );
}

HighEventLoadDetectedNotification.propTypes = {
  details: PropTypes.object.isRequired,
};

export default HighEventLoadDetectedNotification;
