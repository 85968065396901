/* eslint-env browser */
import React from "react";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import ReactAlert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Snackbar from "@mui/material/Snackbar";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useInterval} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import LinkifyText from "../../shared/react/LinkifyText.jsx";
import useJwt from "../hooks/useJwt.jsx";

function DisabledMessage() {
  const defaultDisabledMessage =
    "Creating new studies has been disabled, but you may view Studies and Reports. If you have questions about this, contact your service provider.";

  const {userFacilityId, facilityDisabled} = useJwt();
  const [disabledMessage, setDisabledMessage] = React.useState(null);

  const getDisabledMessage = React.useCallback(async () => {
    if (facilityDisabled) {
      try {
        const {
          data: [facility],
        } = await axios({
          method: "get",
          url: "/facilities",
          params: {id: userFacilityId},
        });

        setDisabledMessage(
          <>
            <AlertTitle>
              <LinkifyText text={facility.disabledMessage} />
            </AlertTitle>
            {defaultDisabledMessage}
          </>
        );
      } catch (err) {
        setDisabledMessage(defaultDisabledMessage);
      }
    }
  }, [userFacilityId, facilityDisabled]);
  useInterval(getDisabledMessage, null, true);

  return (
    <Snackbar
      open={!!disabledMessage}
      anchorOrigin={{vertical: "bottom", horizontal: "center"}}
      sx={{bottom: {xs: 90, sm: 0}, maxWidth: "lg"}}
    >
      <ReactAlert
        severity="warning"
        sx={{mb: {xs: 0, sm: 2}, border: (theme) => `1px solid ${theme.palette.warning.main}`}}
        data-cy="disabled-facility-message"
      >
        {disabledMessage}
      </ReactAlert>
    </Snackbar>
  );
}

export default DisabledMessage;
