/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Copy from "@mui/icons-material/ContentCopy";
import Settings from "@mui/icons-material/Settings";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {useTheme} from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import Alert from "../../shared/react/Alert.jsx";
import IconMenuButton from "../../shared/react/IconMenuButton.jsx";
import useEnvironmentVariables from "../hooks/useEnvironmentVariables.jsx";
import useJwt from "../hooks/useJwt.jsx";
import GravatarImage from "./GravatarImage.jsx";

function AvatarMenu({logout}) {
  //---------------------------------------------------------------------------
  // Global variables
  //---------------------------------------------------------------------------
  const {features, gravatarConnectUrl} = useEnvironmentVariables();
  const {email, fullName, username, userFacilityName, userReadableRole, facilityAccountId} = useJwt();

  const theme = useTheme();

  //---------------------------------------------------------------------------
  // State management
  //---------------------------------------------------------------------------
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = React.useCallback((event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handleLogout = React.useCallback(
    (event) => {
      // Without this preventDefault, the redirect in Layout.jsx fires and overwrites the message
      event.preventDefault();

      // When the React button is clicked, propagate those changes to the Angular page
      if (logout.emit) {
        logout.emit();
      }
    },
    [logout]
  );

  const [message, setMessage] = React.useState(null);
  const [error, setError] = React.useState(null);
  const handleCopyToClipboard = React.useCallback(() => {
    try {
      navigator.clipboard.writeText(facilityAccountId);
      setMessage("Successfully copied to clipboard");
    } catch (err) {
      setError(`Unable to copy contents: ${err.message}`);
    }
  }, [facilityAccountId]);

  return (
    <>
      <Alert message={message} setMessage={setMessage} level="info" variant="snackbar" />
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
      <Button data-cy="avatar-button" onClick={handleOpen}>
        <GravatarImage
          userEmail={email}
          userFullName={fullName}
          sx={{backgroundColor: theme.palette.common.white, color: theme.palette.primary.main}}
        />
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} slotProps={{paper: {sx: {width: 375}}}}>
        <Grid container sx={{p: 2, alignItems: "center", justifyContent: "center"}}>
          <Grid size={4}>
            <Badge
              overlap="circular"
              anchorOrigin={{vertical: "bottom", horizontal: "right"}}
              badgeContent={
                features.userSettingsMenu && (
                  <IconMenuButton
                    buttonSx={{
                      marginBottom: 2,
                      marginRight: 2,
                      color: "white",
                      backgroundColor: "primary.main",
                      "&:hover": {backgroundColor: "primary.dark"},
                    }}
                    icon={
                      <Tooltip placement="top" title="Settings">
                        <Settings fontSize="small" />
                      </Tooltip>
                    }
                  >
                    <MenuItem>
                      <Link
                        href={gravatarConnectUrl}
                        target="_blank"
                        rel="noreferrer"
                        color="inherit"
                        underline="none"
                      >
                        <Typography>Edit Gravatar Profile Image</Typography>
                      </Link>
                    </MenuItem>
                  </IconMenuButton>
                )
              }
            >
              <GravatarImage
                userEmail={email}
                userFullName={fullName}
                sx={{
                  margin: 1,
                  marginLeft: 2,
                  width: "75px",
                  height: "75px",
                  backgroundColor: theme.palette.common.white,
                  color: theme.palette.primary.main,
                  ...(theme.palette.mode === "light" && {borderWidth: "thin", borderStyle: "solid"}),
                  fontSize: "2.25rem",
                }}
              />
            </Badge>
          </Grid>

          <Grid size={8} data-cy="full-name-menu">
            <Typography variant="h6" data-cy="full-name">
              {fullName}
            </Typography>
            <Typography variant="subtitle2">{username}</Typography>
            <Typography variant="body2" sx={{mt: 1, color: "tertiary.light"}}>
              {userReadableRole} - {userFacilityName}
            </Typography>
            {features.facilityAccountId && facilityAccountId && (
              <>
                <Typography variant="caption" data-cy="account-id" sx={{mt: 1, color: "tertiary.light"}}>
                  Account ID {facilityAccountId}
                </Typography>
                <Tooltip title="Copy to clipboard">
                  <IconButton
                    aria-label="account-id-copy"
                    data-cy="copy-account-id-button"
                    color="tertiary.light"
                    size="small"
                    onClick={handleCopyToClipboard}
                  >
                    <Copy fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Grid>
        </Grid>

        <Divider />

        <Grid container sx={{alignItems: "center", justifyContent: "center"}}>
          <Grid size={6} align="center">
            <Button href="/support" target="_blank" sx={{margin: 1}} onClick={handleClose}>
              Support
            </Button>
          </Grid>
          <Grid size={6} align="center">
            <Button sx={{margin: 1}} onClick={handleLogout} data-cy="sign-out-button" id="logoutButton">
              Sign Out
            </Button>
          </Grid>
        </Grid>
      </Menu>
    </>
  );
}

AvatarMenu.propTypes = {
  logout: PropTypes.object.isRequired,
};

export default AvatarMenu;
