import angular from "angular";

import {angularizeDirective} from "../../shared/react-in-angular.js";
import FacilityNotificationWrapper from "./FacilityNotificationWrapper.jsx";

export default angular.module("app.components.facilityNotification", []).name;

// matches <br-facility-notification>
angularizeDirective(
  FacilityNotificationWrapper,
  "brFacilityNotification",
  angular.module("app.components.facilityNotification"),
  {}
);
