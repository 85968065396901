/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import AddProviders from "../../shared/react/AddProviders.jsx";
import AvatarMenu from "./AvatarMenu.jsx";

function AvatarMenuWrapper({logout}) {
  //---------------------------------------------------------------------------
  // NOTE: The only reason that this file is separate from GravatarImage.jsx is because of Angular.
  //  Once we switch completely from Angular to React, AddProviders can be moved to an App.jsx so
  //  that it encapsulates the entire application. Then this file can be deleted.
  //---------------------------------------------------------------------------
  return (
    <AddProviders>
      <AvatarMenu logout={logout} />
    </AddProviders>
  );
}

AvatarMenuWrapper.propTypes = {
  logout: PropTypes.object.isRequired,
};

export default AvatarMenuWrapper;
