import React from "react";
import {DateTime, IANAZone} from "luxon";
import PropTypes from "prop-types";

export function formatDateAndTime({
  // Props - NOTE these are all lowercase to support angular interwork!
  datetime,
  zone,
  seconds,
  format,
  fallback = "Invalid Timestamp",
}) {
  let timeToDisplay;
  if (typeof datetime === "number") {
    timeToDisplay = DateTime.fromMillis(datetime);
  } else if (typeof datetime === "string") {
    timeToDisplay = DateTime.fromISO(datetime);
  } else if (datetime instanceof DateTime) {
    timeToDisplay = datetime;
  } else if (datetime instanceof Date && !Number.isNaN(datetime)) {
    timeToDisplay = DateTime.fromJSDate(datetime);
  }
  if (!timeToDisplay || !timeToDisplay.isValid) {
    return fallback;
  }

  if (zone && IANAZone.isValidZone(zone)) {
    timeToDisplay = timeToDisplay.setZone(zone);
  }

  if (format) {
    return timeToDisplay.toFormat(format);
  }

  if (seconds) {
    return timeToDisplay.toFormat("yyyy-MM-dd HH:mm:ss ZZZZ");
  }
  return timeToDisplay.toFormat("yyyy-MM-dd HH:mm ZZZZ");
}

function DateAndTime({
  // Props - NOTE these are all lowercase to support angular interwork!
  datetime,
  zone,
  seconds,
  format,
  fallback = "Invalid Timestamp",
}) {
  return formatDateAndTime({
    datetime,
    zone,
    seconds,
    format,
    fallback,
  });
}

DateAndTime.propTypes = {
  // The time as either an ISO string, a unix epoch number, a DateTime object, or a JS Date object
  datetime: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,

  // A string with the time zone to use for display, (e.g. "America/New_York")
  zone: PropTypes.string,

  // If true, display seconds in addition to hours and minutes
  seconds: PropTypes.bool,

  // A string for custom Luxon formatting
  format: PropTypes.string,

  // String to display if the input isn't valid
  fallback: PropTypes.string,
};

export default React.memo(DateAndTime);
